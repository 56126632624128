// author: Fuzzic"s Team
// copyright: Fuzzic
// email: fuzzic@gmail.com
// website: www.fuzzic.com

@charset "utf-8";





////////////////////////////////////////////////////////////////////////////////
// Compass imports
////////////////////////////////////////////////////////////////////////////////

@import "compass/utilities/general/clearfix";
@import "compass/css3/transform";
@import "compass/css3/box-sizing";
@import "compass/css3/transition";
@import "compass/css3/border-radius";
@import "compass/css3/box-shadow";
@import "compass/css3/opacity";
@import "compass/css3/images";
@import "compass/css3/flexbox";
@import "compass/css3/animation";
@import "compass/css3/user-interface";





////////////////////////////////////////////////////////////////////////////////
// Icons
////////////////////////////////////////////////////////////////////////////////

@import "mdi/materialdesignicons";





////////////////////////////////////////////////////////////////////////////////
// Styles
////////////////////////////////////////////////////////////////////////////////

// Variables
@import "00_settings/global";
@import "00_settings/grid";

// Mixins - No css output
@import "01_tools/functions";
@import "01_tools/mixins";

// Elements reset and basic styles
@import "02_elements/reset";
@import "02_elements/general";
@import "02_elements/typography";
@import "02_elements/forms";

// Reusable classes (grid, buttons, etc)
@import "03_objects/grid";
@import "03_objects/grid-utilities";
@import "03_objects/buttons";
@import "03_objects/forms";
@import "03_objects/icons";
@import "03_objects/admonitions";

// Specific components with specific classes
@import "04_components/site-header";
@import "04_components/site-main";
@import "04_components/site-bottom";
@import "04_components/site-footer";
@import "04_components/site-news";
@import "04_components/site-partners";
@import "04_components/site-speakers";
@import "04_components/site-schedule";



////////////////////////////////////////////////////////////////////////////////
// Responsive
////////////////////////////////////////////////////////////////////////////////

@media only screen and (max-width: $screen-m) {
    @import "tablet";
}

@media only screen and (max-width: $screen-s) {
    @import "smartphone";
}

////////////////////////////////////////////////////////////////////////////////
// Shame
////////////////////////////////////////////////////////////////////////////////
