////////////////////////////////////////////////////////////////////////////////
// Buttons
////////////////////////////////////////////////////////////////////////////////

.btn {
    @include font-size(12);
    @include border-radius(2px);
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: $gutter / 2 $gutter * 2;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: transparent;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}

.btn--primary {
    // FIXME: background-image from compass is broken
    @include background-image(linear-gradient(to bottom right, $yellow, shade($yellow, 20)));
    color: $almost-white;
    border: 1px solid $white;

    &:hover {
        @include background-image(linear-gradient(to bottom right, $yellow, tint($yellow, 20)));
        background: $white;
        color: $yellow;
    }

    &:focus {
        @include box-shadow(inset 0 0 0 2px rgba($black, .15));
    }

    &:active {
        @include box-shadow(inset 0 1px 0 1px rgba($black, .15));
    }
}

.btn--secondary {
    color: $grey-medium;
    border: 1px solid $grey-medium;

    // We use "secondary" for "disabled"
    &:hover {
        // background: $grey-light;
        color: $grey-medium;
    }

    /*
    &:focus {
        @include box-shadow(inset 0 0 0 2px rgba(black, .15));
        color: $white;
        background: $grey-dark;
    }

    &:active {
        @include box-shadow(inset 0 1px 0 1px rgba(black, .15));
        color: $white;
        background: $grey-dark;
    }
    */
}

.btn--block {
    text-align: center;
    display: block;
    width: 100%;
}
