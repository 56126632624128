.icon {
    line-height: 1;
}

.icon--small {
    width: 24px;
    height: 24px;
}

.icon--medium {
    width: 48px;
    height: 48px;
}
