body {
    @include font-size($base-line);
    font-family: $main-font;
    line-height: $line-height;
    background: $white;
    color: $mine-shaft;
    letter-spacing: .25px;
}

img {
    vertical-align: bottom;
    width: 100%;
    height: auto;
}

a {
    @include transition(all .2s ease);
    color: $blue;
    text-decoration: none;

    &:hover {
        color: $yellow;
    }
}

b,
strong {
    font-weight: bold;
}

i,
em {
    font-style: italic;
}

hr {
    height: 1px;
    background: $mine-shaft;
    border: 0;
    margin: 0;
}




////////////////////////////////////////////////////////////////////////////////
// Utilities
////////////////////////////////////////////////////////////////////////////////

.align-center {
    text-align: center;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.margin-bottom {
    margin-bottom: $gutter;
}

.block {
    width: 100%;
    display: block;
}





////////////////////////////////////////////////////////////////////////////////
// General div
////////////////////////////////////////////////////////////////////////////////

.content {
    background: $white;
    padding: $gutter 0;

    &--small {
        width: 50%;
        margin: 0 auto;
    }
}

.profile-picture {
    @include border-radius(100px);
    width: 50px;
    height: 50px;
}
