
.site-header {
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 4;
    color: $white;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($blue, .9);
        z-index: -1;
    }
}

.site-cta {
    padding-top: $gutter * 2;
    text-align: center;

    .btn {
        margin-right: $gutter;

        &:dir(ltr):last-child {
            margin-right: 0;
        }
        &:dir(rtl):last-child {
            margin-left: 0;
        }
    }
}

%navigation-link {
    font-weight: 700;
    display: block;
    padding: $gutter;
    color: $white;
    line-height: 50px;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.branding-link {
    @extend %navigation-link;
    float: left;
    padding: $gutter 0;

    &:hover {
        color: $yellow;
    }

    img {
        height: 125px;
        width: auto;
    }
}

.navigation--main {
    .navigation--main__list a {
        @extend %navigation-link;

        &:hover {
            color: $yellow;
        }
    }

    ul {
        &:dir(rtl) {
            float: right;
        }
        &:dir(ltr) {
            float: left;
        }
    }

    li {
        display: inline-block;
        margin-left: -4px;

        &.current a {
            @include box-shadow(inset 0 5px 0 $yellow);
            color: $yellow;
        }
    }
}

.site-home-intro {
    padding-top: $gutter * 6;
    padding-bottom: $gutter * 8;
}


.event-date-location {
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;

    i {
        @include font-size(26);
        color: $yellow;
        vertical-align: bottom;
    }
}

.event-date-location__date {
    margin-right: $gutter * 2;
}

.site-title {
    @include font-size(68);
    text-align: center;

    a {
        color: white;

        &:hover {
            color: $yellow;
        }
    }
}

.navigation-hamburger {
    @extend %navigation-link;
    @include font-size(24);
    color: $white;
    display: none;
    position: absolute;
    right: $gutter;
    top: 0;
    z-index: 9999999;

    &:before {
        font-family: "Material Design Icons";
        content: mdi("menu");
    }
}

.site-header__social-links {
    @include font-size(16);
    @include background-image(linear-gradient(to bottom, transparent, rgba($mine-shaft, .4)));
    text-align: center;
    position: relative;
    padding-bottom: $gutter * 2;

    a {
        color: $white;
        display: inline-block;
        margin-right: $gutter * 2;

        &:hover {
            color: $yellow;
        }

        &:dir(ltr):last-child {
            margin-right: 0;
        }
        &:dir(rtl):last-child {
            margin-left: 0;
        }
    }

    i {
        @include font-size(32);
    }
}

.site-internal-intro {
    padding-top: $gutter * 2;
    padding-bottom: $gutter * 3;

    .site-title {
        @include font-size(18);
        letter-spacing: 2px;
    }
}

.page-heading {
    @include background-image(linear-gradient(to bottom right, $blueish, shade($blueish, 20)));
    color: $white;
    background: $blue;
}

.page-heading__title {
    @include font-size(48);
    padding: $gutter * 2;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 0;
}

.navigation--main .lang-menu {
    @include border-radius(0 0 4px 4px);
    position: fixed;
    top: 0;
    right: $gutter;
    background: rgba($blue, .9);

    .lang-menu__item {
        @include font-size(10);
        display: inline-block;
        color: $wild-sand;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 10px;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;

        &:last-child {
            @include border-radius(0 0 4px 0);
        }

        &:first-child {
            @include border-radius(0 0 0 4px);
        }

        &:hover {
            background: $yellow;
            color: $white;
        }
    }
}

