
.site-event-map-container {
    background: $white;
    position: relative;

    h2 {
        padding-top: $gutter * 2;
        padding-bottom: $gutter * 2;
        margin-bottom: 0;
        text-align: center;
    }
}

.site-event-map {
    height: 400px;
}


