// There are two ways to use the flexbox module.
// If you're using the final version of the spec,
// you can use the property mixins as elsewhere in
// the css3 module.
//
// You can also use the flexbox prefixing mixin `flexbox`.
// This mixin takes an optional `$version` argument which
// allows you to specify which spec version the properties
// are using so that they will be prefixed correctly.
//
// Example:
//
//     .flex-column {
//       /* For flexbox spec v1 */
//       @include flexbox((
//         display: box,
//         box-orient: vertical,
//       ), $version: 1);
//       
//       /* For flexbox spec v2 */
//       @include flexbox((
//         display: flexbox,
//         flex-direction: column,
//       ), $version: 2);
//       
//       /* Latest Spec */
//       @include flexbox((
//         display: flex,
//         flex-direction: column,
//       ));
//     }
//
// Which compiles to:
//
//     .flex-row {
//       /* For flexbox spec v1 */
//       display: -moz-box;
//       -moz-box-orient: vertical;
//       display: -webkit-box;
//       -webkit-box-orient: vertical;
//       
//       /* For flexbox spec v2 */
//       display: -ms-flexbox;
//       -ms-flex-direction: column;
//       
//       /* Latest Spec */
//       display: -webkit-flex;
//       -webkit-flex-direction: column;
//       display: flex;
//       flex-direction: column;
//     }

@import "compass/support";

$flexbox-support-threshold: $critical-usage-threshold !default;


// @private
$flexbox-capability-options: (
  (full-support: true),
  (partial-support: true, spec-versions: 3)
);

// This is the underlying implementation for all the other mixins in this module.
// It is the only way to access prefix support for older versions of the spec.
//
// `$properties`: map of property-value pairs that should be prefixed

// `$version1: the version of the spec to use when considering what prefix
// to appply. Defaults to the most recent (spec version 3). Only the most
// recent version of the spec outputs an unprefixed version.
@mixin flexbox($properties, $version: null) {
  $capability-options: $flexbox-capability-options;
  @if $version {
    $capability-options: (partial-support: true, spec-versions: $version);
  }

  @include with-each-prefix(flexbox, $flexbox-support-threshold, $capability-options) {
    // Don't output unprefixed versions when the spec version is not the final version
    @if $version and $current-prefix or not $version or $version == 3 {
      @each $prop, $value in $properties {
        @if $prop == display {
          display: prefix-identifier($value);
        } @else {
          @include prefix-prop($prop, $value);
        }
      }
    }
  }
}

// Values for $display are: flex (default), inline-flex
@mixin display-flex($display: flex) {
  @include flexbox((display: $display));
}

// Values: row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  @include flexbox((flex-direction: $direction));
}

// Values: nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  @include flexbox((flex-wrap: $wrap));
}

// Shorthand for flex-direction and flex-wrap.
@mixin flex-flow($flow) {
  @include flexbox((flex-flow: $flow));
}

// Accepts an integer
@mixin order($order) {
  @include flexbox((order: $order));
}

// Shorthand for flex-grow, flex-shrink and optionally flex-basis.
// Space separated, in that order.
@mixin flex($flex) {
  @include flexbox((flex: $flex));
}

// Accepts a number.
@mixin flex-grow($flex-grow) {
  @include flexbox((flex-grow: $flex-grow));
}

// Accepts a number.
@mixin flex-shrink($flex-shrink) {
  @include flexbox((flex-shrink: $flex-shrink));
}

// Accepts any legal value for the width property.
@mixin flex-basis($flex-basis) {
  @include flexbox((flex-basis: $flex-basis));
}

// Legal values: flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify-content) {
  @include flexbox((justify-content: $justify-content));
}

// Legal values: flex-start | flex-end | center | baseline | stretch
@mixin align-items($align-items) {
  @include flexbox((align-items: $align-items));
}

// Legal values: auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align-self) {
  @include flexbox((align-self: $align-self));
}

// Legal values: flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align-content) {
  @include flexbox((align-content: $align-content));
}
